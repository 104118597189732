import logo from './logo.svg';
import './App.css';
import { Tldraw } from '@tldraw/tldraw'
import '@tldraw/tldraw/tldraw.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <div style={{ position: 'fixed', inset: 0 }} >
          <Tldraw />
        </div>
      </header>
    </div>
  );
}

export default App;
